.page-list-item.styled-item {
  .title {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .path {
    opacity: 0.7;
    margin-left: 1rem;
    font-family: monospace;
    font-size: 0.9rem;
  }
}

.dark-theme {
  .page-list-item.styled-item {
    background: rgba(#fff, 0.05);
  }
}
