@use '@iq/react-components/dist/colors.scss' as colors;

.health-component-type {
  &.full-screen {
    .narrow,
    .wide,
    .widest {
      .grid-content {
        grid-template-columns: repeat(8, 1fr) !important;
        width: 75%;
      }
    }
    &.narrow.grid-content {
      width: 75%;
    }
  }

  .health-panel-component {
    overflow-y: auto;
    width: 100%;
    max-height: 140vh;
    scrollbar-width: 7px;
    scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0);

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    &::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }

    &.empty {
      text-align: center;
      padding: 1rem;
    }

    .loading-container {
      height: auto;
    }

    .component-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 1.3rem;

      .component-name {
        font-size: 1.2rem;
        font-weight: 500;
      }
      .component-id {
        margin-top: 0.2rem;
      }

      .avt-react-components--icon {
        margin-right: 0.3rem;
      }
    }

    .component-status--wrapper {
      display: flex;
      align-items: center;

      border-radius: 0.2rem;
      padding: 0.6rem;
      color: colors.$Grey0;
      margin-bottom: 1.3rem;

      .component-status--icon {
        margin-right: 0.6rem;
      }
    }

    .component-status--empty {
      margin-bottom: 1.3rem;
      padding: 1rem;
      font-size: 1.1rem;
      text-align: center;
    }

    .component-suggestion--wrapper {
      .component-suggestion--title {
        font-weight: 500;
      }

      .component-eventRiskStatus--list {
        background-color: rgb(221, 221, 221);
        padding: 0.063rem 0.625rem 0.625rem;
        margin-bottom: 1.3rem;
      }
      ul {
        margin-top: 0.5rem;
      }

      li {
        margin: 0.25rem 0 0.25rem 0.6rem;

        &::before {
          content: '•';
          margin-right: 0.6rem;
          font-size: 1.4rem;
        }
      }

      .component-suggestion--empty,
      .component-mitigation--empty {
        padding: 1rem;
        font-size: 1.1rem;
        text-align: center;
      }

      .component-status--wrapper {
        margin-top: 1.3rem;
      }
    }

    &.narrow {
      .grid-content {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &.wide,
    &.widest {
      .grid-content {
        grid-template-columns: repeat(8, 1fr);
      }
    }

    &.widest {
      .grid-content {
        width: 75%;
      }
    }

    /*Risk panel*/
    .styled-item {
      background: #f2f2f2;
      border: none;
      align-items: flex-start;

      &.closed {
        padding-top: 5px;
        height: 100%;

        .risk-eventtext {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &.open {
        padding-top: 5px;
        background: #deebfa;
      }

      @media only screen and (max-width: 1500px) {
        .grid {
          .grid-item {
            min-width: 70px;
          }

          .grid-content {
            grid-gap: 0;
          }
        }
      }

      &__header__label {
        min-width: 0;
        margin-right: 0.3rem;
        flex-direction: row;
        flex: 2;
        justify-content: space-between;
        .title {
          padding-left: 4px;
          align-items: center;
        }
      }

      .styled-item__header {
        width: 100%;
        height: 100%;
        padding: 0 1rem;
        flex-wrap: wrap;

        &__actions {
          margin-bottom: 3.3rem;
        }
        .risk-title {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin: 0.5rem 0;

          .risk-title-status,
          .risk-title-status > div {
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-bottom: 2px;
          }
          .risk-title-info {
            display: flex;
            flex-direction: column;

            .risk-eventtext {
              padding: 8px 0 5px 0;
              font-weight: 500;
            }
            .risk-component {
              font-size: 0.9rem;
              color: colors.$Grey50;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .styled-item__body {
        width: 100%;
        padding: 0.2rem 1rem 1rem 1rem;
        .risk-message {
          font-size: 0.75rem;
        }
        .risk-body {
          padding: 0.75rem;
          background: #fff;
          border-radius: 0.3rem;
          margin: 0.5rem 0;
          line-height: 1.2rem;
          overflow: hidden;

          &.mitigation {
            div {
              padding-bottom: 5px;
            }
          }
          .risk-body-title {
            font-weight: 600;
            align-items: flex-start;
            span {
              font-weight: 400;
              font-size: 12px;
            }
          }
          .grid-content {
            display: grid;
            align-items: center;
            border-radius: 0.3rem;
            margin-bottom: 0.6rem;
            .grid-item {
              .circle {
                margin-top: 0.6rem;
              }
            }
          }
        }
        .source-event {
          display: flex;
          justify-content: flex-end;
          padding-top: 0.5rem;
        }
      }
    }

    .grid {
      display: flex;
      flex-flow: column;

      .grid-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        min-width: 90px;
        box-sizing: border-box;

        .circle {
          width: 50px;
          height: 50px;
          line-height: 52px;
          border-radius: 50%;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (max-width: 768px) {
  .health-panel-component.wide,
  .health-panel-component.widest {
    .grid-content.narrow {
      grid-template-columns: repeat(4, 1fr) !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .health-component-type {
    &.full-screen {
      .narrow {
        .grid-content {
          grid-template-columns: repeat(4, 1fr) !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .health-panel-component.wide {
    .grid-content.narrow {
      grid-template-columns: repeat(4, 1fr) !important;
    }
  }
}

@media only screen and (max-width: 1250px) {
  .health-component-type:not(.full-screen)
    .health-panel-component.narrow
    .styled-item__header
    .risk-title-status {
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 460px) {
  .health-component-type .health-panel-component .styled-item__header__label {
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.light-theme {
  .health-panel-component {
    &.empty {
      color: colors.$LightApplicationSecondaryText;
    }

    .component-id {
      color: colors.$LightApplicationSecondaryText;
    }

    .component-suggestion--empty,
    .component-mitigation--empty {
      color: colors.$LightApplicationSecondaryText;
    }

    .component-status--empty {
      color: colors.$LightApplicationSecondaryText;
    }
  }
}

.dark-theme {
  .health-panel-component {
    &.empty {
      color: colors.$DarkApplicationSecondaryText;
    }

    .component-id {
      color: colors.$DarkApplicationSecondaryText;
    }

    .component-suggestion--empty,
    .component-mitigation--empty {
      color: colors.$DarkApplicationSecondaryText;
    }

    .component-status--empty {
      color: colors.$DarkApplicationSecondaryText;
    }

    .risk-list-item.styled-item {
      background: #262626;
    }

    .styled-item .styled-item__body .risk-body {
      background: #333333;
    }
  }
}
